import React, { Component } from "react"
import PropTypes from "prop-types"
import { IconButton, Checkbox } from "@material-ui/core"
import { FiTrash2, FiPlusCircle, FiArrowUp, FiArrowDown, FiSearch } from "react-icons/fi";

import moment from "moment"

import "./table.scss"

class Table extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedRow: false,
      sortField: "",
      search: "",
      sortOrder: "DESC",
      data: this.props.data,
      filteredData: this.props.data,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.data !== this.state.data) {
      this.setState({ data: this.state.data, filteredData: this.state.data })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data !== prevState.data) {
      return { data: nextProps.data }
    } else return null
  }

  onRowClick = selectedRow => {
    this.setState({ selectedRow })
    if (this.props.handleSelection) this.props.handleSelection(selectedRow)
  }

  onHeaderClick = field => {
    let sortOrder
    if (this.state.sortField != field) {
      sortOrder = "DESC"
    } else {
      sortOrder = this.state.sortOrder === "ASC" ? "DESC" : "ASC"
    }
    const data = this.state.filteredData
    data.sort((a, b) => {
      if (sortOrder === "ASC") {
        if (a[field] < b[field]) {
          return -1
        }
        if (a[field] > b[field]) {
          return 1
        }
      } else {
        if (a[field] < b[field]) {
          return 1
        }
        if (a[field] > b[field]) {
          return -1
        }
      }
      return 0
    })
    this.setState({
      filteredData: data,
      sortField: field,
      sortOrder: sortOrder,
    })
  }

  onSearchValueChanged = e => {
    let search = e.target.value
    if (search != undefined) {
      search = search.toLowerCase()
      const data = this.state.data
      const filteredData = data.filter(item => {
        var contains = false
        this.props.columns.forEach(column => {
          const field = column.field
          if (
            item[field] !== undefined &&
            item[field] !== null &&
            item[field].toString().toLowerCase().indexOf(search) > -1
          ) {
            contains = true
            return
          }
        })
        if (contains) return item
      })

      this.setState({ filteredData: filteredData, search: search })
    } else {
      this.setState({ search: search })
    }
  }

  onEditableColumnValueChanged = (event, itemModified) => {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    let newItem = {
      ...itemModified,
      [name]: value,
    }
    if (this.props.handleColumnEdit) {
      this.props.handleColumnEdit(newItem)
    }
  }

  onDelete = () => {}

  render() {
    const {
      props: {
        title,
        columns,
        options = {},
        sort,
        data,
        handleAdd,
        handleEdit,
        handleDelete,
        handleSelection,
        handleColumnEdit,
        className = "",
        ...other
      },
      state: { filteredData, selectedRow },
    } = this

    const hasHeader = options.search || title || handleAdd

    return (
      <div
        className={`app-table ${className} ${
          options.noPadding ? "nopadding" : "p-2"
        }`}
        {...other}
      >
        {hasHeader ? (
          <div className="app-table-header" style={{ ...options.headerStyle }}>
            {options.search ? (
              <div className="app-table-search">
                <input
                  value={this.state.search}
                  onChange={this.onSearchValueChanged}
                  type="search"
                  placeholder="Rechercher"
                />
                <FiSearch className="m-auto" />
              </div>
            ) : null}
            {title ? (
              <div className="app-table-title">
                <h3>{title}</h3>
              </div>
            ) : null}
            {handleAdd ? (
              <div className="app-table-add">
                <button
                  className="app-table-button-icon"
                  onClick={handleAdd}
                  size="small"
                >
                  <FiPlusCircle />
                </button>
              </div>
            ) : null}
          </div>
        ) : null}

        <table {...other}>
          <thead className="app-table-head">
            <tr className="app-table-row">
              {columns.length > 0 &&
                columns.map(column => {
                  return (
                    <td
                      key={column.field}
                      onClick={() => this.onHeaderClick(column.field)}
                    >
                      {column.title}

                      <IconButton size="small">
                        {this.state.sortField === column.field ? (
                          this.state.sortOrder === "ASC" ? (
                            <FiArrowDown />
                          ) : (
                            <FiArrowUp />
                          )
                        ) : (
                          <FiArrowDown />
                        )}
                      </IconButton>
                    </td>
                  )
                })}
              {handleDelete ? <td></td> : null}
            </tr>
          </thead>
          <tbody className="app-table-body">
            {filteredData &&
              filteredData.length > 0 &&
              filteredData.map(item => {
                let rowStyle = {}
                if (options?.affectRowColor?.length > 0) {
                  options.affectRowColor.forEach(condition => {
                    if (
                      (!condition.inversed && item[condition.column]) ||
                      (condition.inversed && !item[condition.column])
                    ) {
                      rowStyle = condition.style
                    }
                  })
                }
                return (
                  <tr
                    key={item.id}
                    className={`app-table-row ${
                      item.id === selectedRow.id &&
                      (!options || !options?.noSelection)
                        ? "active"
                        : ""
                    }`}
                    onClick={() => this.onRowClick(item)}
                    style={rowStyle}
                  >
                    {columns.map(column => {
                      var properties = Array.isArray(column.field)
                        ? column.field
                        : column.field.split(".")
                      var value = properties.reduce(
                        (prev, curr) => prev && prev[curr],
                        item
                      )

                      let onChangeEvent = null
                      if (column.editable) {
                        onChangeEvent = event =>
                          this.onEditableColumnValueChanged(event, item)
                      }
                      if (column.boolean) {
                        return (
                          <td
                            key={column.field}
                            style={{ width: "1%", whiteSpace: "nowrap" }}
                          >
                            <Checkbox
                              color="primary"
                              name={column.field}
                              checked={value == 1 ? true : false}
                              onChange={onChangeEvent}
                              value={value}
                            />
                          </td>
                        )
                      } else {
                        return (
                          <td key={column.field}>
                            {column.editable ? (
                              <input
                                name={column.field}
                                type="text"
                                onChange={onChangeEvent}
                                value={
                                  column.dateFormat
                                    ? moment(value).format("DD/MM/YYYY HH:mm")
                                    : value
                                }
                              />
                            ) : (
                              <span>
                                {column.dateFormat
                                  ? moment(value).format("DD/MM/YYYY HH:mm")
                                  : value}
                              </span>
                            )}
                          </td>
                        )
                      }
                    })}
                    {handleDelete || handleEdit ? (
                      <td style={{ width: "1%", whiteSpace: "nowrap" }}>
                        <div className="app-table-actions float-right">
                          {handleEdit ? (
                            <button
                              className="app-table-button-icon"
                              onClick={() => handleEdit(item)}
                              size="small"
                            >
                              <FiSearch />
                            </button>
                          ) : null}
                          {handleDelete ? (
                            <button
                              className="app-table-button-icon"
                              color="danger"
                              onClick={() => handleDelete(item)}
                              size="small"
                            >
                              <FiTrash2 />
                            </button>
                          ) : null}
                        </div>
                      </td>
                    ) : null}
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    )
  }
}

Table.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  options: PropTypes.object.isRequired,
  handleAdd: PropTypes.func,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  handleRowSelected: PropTypes.func,
  handleColumnEdit: PropTypes.func,
}

export default Table
