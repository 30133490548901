import React, { Component } from "react"
import PropTypes from "prop-types"
import { getUsers } from "../../services/users"
import Table from "../../components/app/Table"

export class Users extends Component {
  static propTypes = {}

  constructor(props) {
    super(props)

    this.state = {
      users: [],
    }
  }

  componentDidMount() {
    this._isMounted = true
    getUsers().then(users => {
      if (this._isMounted) {
        this.setState({ users })
      }
    })
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { users } = this.state

    return (
      <div>
        <h1>Users</h1>
        <Table
          columns={[
            { title: "Nom", field: "lastName" },
            { title: "Prénom", field: "firstName" },
            { title: "Téléphone", field: "phone" },
            { title: "Email", field: "email" },
          ]}
          data={users}
          handleSelection={this.onRowSelected}
          options={{
            search: true,
            selectedRowColor: "var(--gray)",
            affectRowColor: [
              {
                column: "needPayment",
                style: { backgroundColor: "var(--color-warning-alpha)" },
              },
              {
                column: "needPaymentUrgent",
                style: { backgroundColor: "var(--color-danger-alpha)" },
              },
            ],
          }}
          style={{ minWidth: "100%" }}
        />
      </div>
    )
  }
}

export default Users
