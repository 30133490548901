import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/app/layout"
import Profile from "./app/profile"
import { navigate } from "gatsby"
import { isLoggedIn } from "../services/auth"
import Users from "./app/users"
import { NotificationContainer } from "react-notifications"
import "../styles/app.scss"
import Calendar from "./app/calendar"
import { isBrowser } from "../services/helper"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isLoggedIn() && location.pathname.indexOf(`/app/login`) == -1) {
    navigate("/login")
    return null
  }
  return <Component {...rest} />
}

const App = ({ location }) => {
  if (isBrowser() && !isLoggedIn()) navigate("/login")

  return (
    <>
      <NotificationContainer />

      <Router>
        <Layout
          currentPage={
            "/app" + location.pathname.split("?")[0].split("/app")[1]
          }
          path="/"
        >
          <PrivateRoute component={Profile} path="/app/profile" />
          <PrivateRoute component={Users} path="/app/users/:id" />
          <PrivateRoute component={Users} path="/app/users" />
          <PrivateRoute component={Calendar} path="/app/calendar/list/:day" />
          <PrivateRoute component={Calendar} path="/app/calendar/:id" />
          <PrivateRoute component={Calendar} path="/app/calendar" />
        </Layout>
      </Router>
    </>
  )
}

export default App
