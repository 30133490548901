import React, { Component } from "react"
import { getMyProfile } from "../../services/users"

export default class Profile extends Component {
  state = {
    user: null,
  }

  componentDidMount() {
    this._isMounted = true
    getMyProfile()
      .then(user => {
        console.log(user);
        if (this._isMounted) this.setState({ user })
      })
      .catch(e => console.error(e))
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { user } = this.state

    return (
      <>
        <h1>Your profile</h1>
        {user && (
          <ul>
            <li>Name: {user.name}</li>
            <li>E-mail: {user.email}</li>
          </ul>
        )}
      </>
    )
  }
}
