import { get, post } from "./fetch";

export const getUsers = () => new Promise((resolve, reject) => {
  get("users").then(data => {
    resolve(data);
  }).catch(error => {
    reject(error);
  })
});

export const getUser = (id = null) => new Promise((resolve, reject) => {
  get("users/"+id).then(data => {
    resolve(data);
  }).catch(error => {
    reject(error);
  })
});

export const getMyProfile = () => new Promise((resolve, reject) => {
  get("users/myprofile").then(data => {
    resolve(data);
  }).catch(error => {
    reject(error);
  })
});